<template>
  <div>
    <div class="sidebar" :class="{ open: sidebarOpen }">
      <div class="logo-container">
        <img src="@/assets/logo.svg" alt="Logo Large" class="logo-large">
      </div>
      <a href="/home">Stimmungen</a>
      <a href="/post">Beitrag</a>
      <a href="/analytics">Analytics</a>
      <a href="/settings">Einstellungen</a>
      <a href="/administration">Administration</a>
      <a href="#" @click.prevent="logout">Logout</a>
    </div>

    <button class="openbtn" @click="toggleSidebar">
      <span v-if="sidebarOpen">&times;</span>
      <span v-if="!sidebarOpen">☰</span>
    </button>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SideBar',
  data() {
    return {
      sidebarOpen: false
    };
  },
  methods: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    async logout() {
      try {
        await axios.post('https://api.moodboard.one/logout', {}, { withCredentials: true });
        this.$router.push('/');
      } catch (error) {
        console.error('Logout failed:', error);
      }
    }
  }
};
</script>

<style scoped>
body {
  background-color: #191624; /* Hintergrund */
  color: #ffffff; /* Standard Schriftfarbe */
}

.sidebar {
  height: 100%;
  width: 250px;
  position: fixed;
  top: 0;
  left: -250px; /* Hidden by default */
  background-color: #2e2b39; /* Hintergrundfarbe Sidebar */
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 50px;
  z-index: 1;
  border-right: 1px solid #6b6b6d; /* Rahmenfarbe */
}

.logo-container {
  text-align: center;
  padding: 10px 0;
}

.sidebar a {
  padding: 10px 15px;
  text-decoration: none;
  font-size: 18px;
  color: #908d96; /* Schriftfarbe */
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #a688fa; /* Schriftfarbe bei Hover */
}

.openbtn {
  font-size: 20px;
  cursor: pointer;
  background-color: #2e2b39; /* Hintergrundfarbe Button */
  color: #ffffff; /* Schriftfarbe Button */
  padding: 10px 15px;
  border: none;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1;
  width: 50px;
  height: 50px;
}

.openbtn:hover {
  background-color: #444; /* Hintergrundfarbe bei Hover */
}

.sidebar.open {
  left: 0;
}

.logo-small {
  width: 24px; /* Passe die Größe nach Bedarf an */
  height: 24px; /* Passe die Größe nach Bedarf an */
}

.logo-large {
  width: 80%; /* Passe die Größe nach Bedarf an */
  height: 48px; /* Passe die Größe nach Bedarf an */
}

@media screen and (max-height: 450px) {
  .sidebar {padding-top: 50px;}
  .sidebar a {font-size: 18px;}
}

@media screen and (max-width: 600px) {
  .sidebar {
    width: 100%;
    left: -100%;
  }
  .sidebar.open {
    left: 0;
  }
}
</style>
