<template>
  <div class="container">
    <SideBar />
    <div class="content">
      <div class="grid">
        <div class="grid-item1">
          <h2>Teile Deine Gedanken</h2>
          <p>Ob Verbesserungsvorschläge, Problemmeldungen oder einfach Deine aktuelle Stimmung - lass es uns wissen. Deine Meinung ist uns wichtig, und durch Dein Feedback können wir gemeinsam wachsen und besser werden.</p>
        </div>
        <div class="grid-item2">
          <h2>Deine Beiträge sind anonym</h2>
          <p>Dein Beitrag wird absolut vertraulich behandelt. Andere Nutzer können Deine Beiträge sehen, aber niemand wird erfahren, wer sie geschrieben hat. Deine Identität bleibt geschützt, damit Du ohne Sorge Deine ehrlichen Gedanken teilen kannst.</p>
        </div>
        <div class="grid-item3">
          <h2>Verhalte Dich respektvoll</h2>
          <p>Bitte stelle sicher, dass Dein Beitrag angemessen ist und unseren Verhaltenskodex respektiert. Durch respektvolle und konstruktive Beiträge tragen wir alle zu einem positiven und unterstützenden Umfeld bei.</p>
        </div>
      </div>
      <div class="box1">
        <h2>Wie fühlst Du Dich heute?</h2>
        <div class="mood-selector">
          <img
            v-for="mood in reversedMoods"
            :key="mood.id"
            :src="mood.icon"
            :alt="mood.name"
            class="mood-icon"
            @click="selectMood(mood.id)"
            :class="{ selected: selectedMood === mood.id }"
          />
        </div>
      
        <textarea
          v-model="postContent"
          placeholder="Was beschäftigt Dich?"
          class="post-textbox"
        ></textarea>
        <div class="emoji-picker">
          <i class="fas fa-smile"></i>
        </div>

      
        <button @click="submitPost" class="submit-button">Stimmung veröffentlichen</button>
      </div>
    </div>
  </div>
</template>

<script>
import mood1 from "@/assets/1.svg";
import mood2 from "@/assets/2.svg";
import mood3 from "@/assets/3.svg";
import mood4 from "@/assets/4.svg";
import mood5 from "@/assets/5.svg";
import SideBar from './SideBar.vue';
import axios from 'axios';

export default {
  name: "PostPage",
  components: {
    SideBar
  },
  data() {
    return {
      moods: [
        { id: 1, name: "Sad", icon: mood1 },
        { id: 2, name: "Slightly Sad", icon: mood2 },
        { id: 3, name: "Neutral", icon: mood3 },
        { id: 4, name: "Slightly Happy", icon: mood4 },
        { id: 5, name: "Happy", icon: mood5 },
      ],
      selectedMood: null,
      postContent: "",
    };
  },
  computed: {
    reversedMoods() {
      return this.moods.slice().reverse();
    }
  },
  methods: {
    selectMood(moodId) {
      this.selectedMood = moodId;
    },
    submitPost() {
      const postData = {
        mood: this.selectedMood,
        message: this.postContent
      };

      fetch('https://api.moodboard.one/post', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        if (data.id) {
          alert('Beitrag erfolgreich erstellt.'); // Erfolgsnachricht als Alert
        } else {
          alert('Fehler beim Erstellen des Beitrags: ' + data.error); // Fehlermeldung als Alert
        }
        console.log('Post created successfully:', data);
        this.postContent = '';
        this.selectedMood = null;
      })
      .catch((error) => {
        alert('Fehler beim Erstellen des Beitrags: ' + error);
        console.error('Error creating post:', error);
      });
    },
    async checkToken(token) {
      try {
        const response = await axios.get(`https://api.moodboard.one/post/token/${token}`, { withCredentials: true });
        if (!response.data.success) {
          this.$router.push('/');
        }
      } catch (error) {
        console.error('Error checking token:', error);
        this.$router.push('/');
      }
    },
    getMoodFromQuery() {
      const params = new URLSearchParams(window.location.search);
      const mood = parseInt(params.get('m'));
      if (mood >= 1 && mood <= 5) {
        this.selectedMood = mood;
      }
    }
  },
  created() {
    const token = this.$route.params.id;
    if (token) {
      this.checkToken(token);
    }
    this.getMoodFromQuery();
  }
};
</script>

<style scoped>
body {
  background-color: #191624; /* Hintergrund */
  color: #ffffff; /* Standard Schriftfarbe */
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.content {
  background-color: #191624; /* Hintergrund */
  padding: 20px;
  border-radius: 10px;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
  text-align: left;
}

.grid-item1,
.grid-item2 {
  background-color: #2e2b39; /* Hintergrund der Grid-Items */
  padding: 20px;
  border-radius: 10px;
}

.grid-item3 {
  grid-column: span 2;
  background-color: #2e2b39; /* Hintergrund der Grid-Items */
  padding: 20px;
  border-radius: 10px;
}

.grid-item1 h2,
.grid-item2 h2,
.grid-item3 h2 {
  color: #fff; /* Schriftfarbe für Titel */
}

.grid-item1 p,
.grid-item2 p,
.grid-item3 p {
  color: #908d96; /* Schriftfarbe für Text */
}

.box1,
.box2,
.box3 {
  margin-top: 20px;
  background-color: #2e2b39; /* Hintergrund */
  padding: 20px;
  border-radius: 10px;
}

h2 {
  margin-bottom: 10px;
  color: #a688fa; /* Schriftfarbe für Titel */
}

.mood-selector {
  display: flex;
  justify-content: center;
}

.mood-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin: 10px;
  box-sizing: border-box;
}

.mood-icon.selected {
  border: 2px solid #007bff;
  border-radius: 50%;
}

.post-textbox {
  width: 100%;
  height: 150px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #6b6b6d; /* Rahmenfarbe */
  border-radius: 5px;
  resize: none;
  background-color: #2c2936; /* Hintergrund */
  color: #6b6b6d; /* Schriftfarbe */
}

.post-textbox:focus {
  border-color: #a688fa; /* Rahmenfarbe bei Fokus */
  outline: none;
}

.emoji-picker {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #a688fa; /* Farbe des Emoji-Pickers */
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #a688fa; /* Hintergrundfarbe */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button:hover {
  background-color: #9162fa; /* Hintergrundfarbe bei Hover */
}
</style>
