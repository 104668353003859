<template>
  <div id="app">
    <router-view/>
  </div>
  <footer style="margin-top: 50px">
    <div class="container">
      <a href="#">Impressum</a> | <a href="#">Datenschutz</a> | Blubb.
    </div>
  </footer>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
body {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: #191624;
  color: #ffffff; /* Standard Schriftfarbe */

}
#app {
  text-align: center;
  color: #fff;
  margin-top: 60px;
}
</style>
