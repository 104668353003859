<template>
  <div class="login-container">
    <h2>Login</h2>
    <form @submit.prevent="handleLogin">
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" v-model="email" required />
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" v-model="password" required />
      </div>
      <button type="submit">Login</button>
    </form>
  </div>
</template>

<script>
export default {
  name: 'UserLogin',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    handleLogin() {
      console.log('Email:', this.email);
      console.log('Password:', this.password);
      const backendUrl = process.env.VUE_APP_BACKEND_URL;
      fetch(`${backendUrl}/login`, {        
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            this.$router.push('/home');
          } else {
            alert('Login failed');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
  },
};
</script>

<style scoped>
body {
  background-color: #191624;
  color: #ffffff;
}

.login-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #2e2b39;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #a688fa;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #908d96;
}

input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #6b6b6d;
  border-radius: 4px;
  background-color: #2c2936; /* Hintergrundfarbe des Eingabefelds */
  color: #6b6b6d; /* Schriftfarbe des Eingabefelds */
}

input::placeholder {
  color: #6b6b6d;
}

input:focus {
  border-color: #a688fa;
  outline: none;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #a688fa;
  border: none;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #9162fa;
}
</style>
