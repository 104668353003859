<template>
  <div class="settings-container">
    <SideBar />

    <h2>Benutzereinstellungen</h2>

    <!-- Formular zum Ändern der E-Mail-Adresse -->
    <form @submit.prevent="updateEmail">
      <div class="form-group">
        <label for="current-email">Aktuelle E-Mail</label>
        <input type="email" v-model="currentEmail" id="current-email" disabled />
      </div>
      <div class="form-group">
        <label for="new-email">Neue E-Mail</label>
        <input type="email" v-model="newEmail" id="new-email" required />
      </div>
      <div class="form-group">
        <label for="confirm-new-email">Neue E-Mail bestätigen</label>
        <input type="email" v-model="confirmNewEmail" id="confirm-new-email" required />
      </div>
      <button type="submit" class="btn">E-Mail aktualisieren</button>
    </form>

    <!-- Formular zum Ändern des Passworts -->
    <form @submit.prevent="updatePassword">
      <div class="form-group">
        <label for="old-password">Altes Passwort</label>
        <input type="password" v-model="oldPassword" id="old-password" required />
      </div>
      <div class="form-group">
        <label for="new-password">Neues Passwort</label>
        <input type="password" v-model="newPassword" id="new-password" required />
      </div>
      <div class="form-group">
        <label for="confirm-new-password">Neues Passwort bestätigen</label>
        <input type="password" v-model="confirmNewPassword" id="confirm-new-password" required />
      </div>
      <button type="submit" class="btn">Passwort ändern</button>
    </form>

    <!-- Formular für Benachrichtigungseinstellungen -->
    <form @submit.prevent="updateNotifications">
      <div class="form-group">
        <label>Benachrichtigungstage</label>
        <div class="checkbox-group">
          <div v-for="day in days" :key="day.value" class="checkbox-item">
            <input type="checkbox" :id="day.value" v-model="notifications[day.value]" />
            <label :for="day.value">{{ day.label }}</label>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="notification-time">Benachrichtigungszeit</label>
        <input type="time" v-model="notificationTime" id="notification-time" required />
      </div>
      <div class="form-group">
        <label for="time-zone">Zeitzone</label>
        <select v-model="timeZone" id="time-zone" required>
          <option v-for="zone in timeZones" :key="zone" :value="zone">{{ zone }}</option>
        </select>
      </div>
      <button type="submit" class="btn">Benachrichtigungen aktualisieren</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';
import SideBar from './SideBar.vue';

export default {
  name: 'SettingsPage',
  components: {
    SideBar
  },
  data() {
    return {
      currentEmail: '', // Wird beim Laden der Seite gesetzt
      newEmail: '',
      confirmNewEmail: '',
      oldPassword: '',
      newPassword: '',
      confirmNewPassword: '',
      notificationTime: '08:00', // Standardzeit
      timeZone: 'Europe/Berlin', // Standardzeitzone
      notifications: {
        saturday: false,
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false
      },
      days: [
        { value: 'sunday', label: 'Sonntag' },
        { value: 'monday', label: 'Montag' },
        { value: 'tuesday', label: 'Dienstag' },
        { value: 'wednesday', label: 'Mittwoch' },
        { value: 'thursday', label: 'Donnerstag' },
        { value: 'friday', label: 'Freitag' },
        { value: 'saturday', label: 'Samstag' }
      ],
      timeZones: [
        'Europe/Berlin',
        'America/New_York',
        'Asia/Tokyo',
        'Australia/Sydney',
        'Europe/London',
        'Europe/Paris',
        'Europe/Madrid',
        'America/Los_Angeles',
        'America/Chicago',
        'America/Denver'
        // Weitere Zeitzonen hier hinzufügen
      ]
    };
  },
  methods: {
    async updateEmail() {
      if (this.newEmail !== this.confirmNewEmail) {
        alert('Die neue E-Mail-Adresse und die Bestätigung stimmen nicht überein.');
        return;
      }

      try {
        const response = await axios.put('https://api.moodboard.one/users/email', {
          email: this.newEmail,
          emailConfirmation: this.confirmNewEmail
        }, { withCredentials: true });

        if (response.data.message) {
          alert('E-Mail erfolgreich aktualisiert!');
          this.currentEmail = this.newEmail;
          this.newEmail = '';
          this.confirmNewEmail = '';
        } else {
          alert('Fehler beim Aktualisieren der E-Mail.');
        }
      } catch (error) {
        console.error('Fehler beim Aktualisieren der E-Mail:', error);
        alert('Es ist ein Fehler aufgetreten.');
      }
    },
    async updatePassword() {
      if (this.newPassword !== this.confirmNewPassword) {
        alert('Das neue Passwort und die Bestätigung stimmen nicht überein.');
        return;
      }

      try {
        const response = await axios.put('https://api.moodboard.one/users/password', {
          oldPassword: this.oldPassword,
          password: this.newPassword,
          passwordConfirmation: this.confirmNewPassword
        }, { withCredentials: true });

        if (response.data.message) {
          alert('Passwort erfolgreich geändert!');
          this.oldPassword = '';
          this.newPassword = '';
          this.confirmNewPassword = '';
        } else {
          alert('Fehler beim Ändern des Passworts.');
        }
      } catch (error) {
        console.error('Fehler beim Ändern des Passworts:', error);
        alert('Es ist ein Fehler aufgetreten.');
      }
    },
    async updateNotifications() {
      try {
        const response = await axios.put('https://api.moodboard.one/notifications/update', {
          time: this.notificationTime,
          tz: this.timeZone,
          saturday: this.notifications.saturday,
          sunday: this.notifications.sunday,
          monday: this.notifications.monday,
          tuesday: this.notifications.tuesday,
          wednesday: this.notifications.wednesday,
          thursday: this.notifications.thursday,
          friday: this.notifications.friday
        }, { withCredentials: true });

        if (response.data.success) {
          alert('Benachrichtigungseinstellungen erfolgreich aktualisiert!');
        } else {
          alert('Fehler beim Aktualisieren der Benachrichtigungseinstellungen.');
        }
      } catch (error) {
        console.error('Fehler beim Aktualisieren der Benachrichtigungseinstellungen:', error);
        alert('Es ist ein Fehler aufgetreten.');
      }
    }
  },
  async created() {
    try {
      const profileResponse = await axios.get('https://api.moodboard.one/user/profile', { withCredentials: true });
      if (profileResponse.data.success) {
        this.currentEmail = profileResponse.data.email;
      } else {
        alert('Fehler beim Laden des Profils.');
      }

      const notificationsResponse = await axios.get('https://api.moodboard.one/notifications', { withCredentials: true });
      if (notificationsResponse.data.success) {
        const notification = notificationsResponse.data;
        this.notificationTime = notification.time;
        this.timeZone = notification.tz;
        this.notifications.saturday = notification.saturday;
        this.notifications.sunday = notification.sunday;
        this.notifications.monday = notification.monday;
        this.notifications.tuesday = notification.tuesday;
        this.notifications.wednesday = notification.wednesday;
        this.notifications.thursday = notification.thursday;
        this.notifications.friday = notification.friday;
      } else {
        alert('Fehler beim Laden der Benachrichtigungseinstellungen.');
      }
    } catch (error) {
      console.error('Fehler beim Laden der Einstellungen:', error);
      alert('Es ist ein Fehler aufgetreten.');
    }
  }
};
</script>

<style scoped>
body {
  background-color: #191624; /* Hintergrund */
  color: #ffffff; /* Standard Schriftfarbe */
}

.settings-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #2e2b39; /* Hintergrund */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #a688fa; /* Schriftfarbe für Titel */
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #ffffff; /* Schriftfarbe für Label */
}

input,
select {
  width: 100%;
  padding: 10px;
  background-color: #2c2936; /* Hintergrund Input Field */
  color: #6b6b6d; /* Schriftfarbe Input Field */
  border: 1px solid #6b6b6d;
  border-radius: 5px;
  box-sizing: border-box;
}

input:focus,
select:focus {
  border-color: #a688fa; /* Rahmenfarbe bei Fokus */
  outline: none;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-item {
  width: 50%;
}

input[type="checkbox"] {
  margin-right: 10px;
}

input[type="time"] {
  background-color: #2c2936; /* Hintergrund Input Field */
  color: #6b6b6d; /* Schriftfarbe Input Field */
  border: 1px solid #6b6b6d;
  border-radius: 5px;
  padding: 5px;
}

input[type="time"]:focus {
  border-color: #a688fa; /* Rahmenfarbe bei Fokus */
  outline: none;
}

.btn {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #a688fa; /* Hintergrundfarbe Button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.btn:hover {
  background-color: #9162fa; /* Hintergrundfarbe Button bei Hover */
}
</style>
