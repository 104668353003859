<template>
  <div class="chart-container">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { Chart, LineController, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend } from 'chart.js';

Chart.register(LineController, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend);

export default {
  props: ['chartData'],
  mounted() {
    const labels = Object.keys(this.chartData);
    const data = Object.values(this.chartData);

    new Chart(this.$refs.canvas, {
      type: 'line',
      data: {
        labels,
        datasets: [{
          label: 'Durchschnittliche Stimmung',
          data,
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: function(context) {
                return `Datum: ${context[0].label}`;
              },
              label: function(context) {
                const value = context.raw;
                return `Durchschnittliche Stimmung: ${value}`;
              }
            }
          }
        }
      }
    });
  }
};
</script>

<style scoped>
.chart-container {
  width: 100%;
}
</style>
