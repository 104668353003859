<template>
  <div class="container">
    <SideBar />
    <div class="content">
      <h2>Analytics</h2>
      <div>
        <label for="startDate">Von: </label>
        <input type="date" v-model="startDate" id="startDate" />
        <label for="endDate">Bis: </label>
        <input type="date" v-model="endDate" id="endDate" />
        <button @click="fetchAnalytics">Fetch Data</button>
      </div>
      <div class="analytics-summary" v-if="avgMood !== null || bestDay || worstDay">
        <div v-if="avgMood !== null">
          <h3>Durchschnittliche Stimmung</h3>
          <p>{{ avgMood }}</p>
        </div>
        <div v-if="bestDay">
          <h3>Bester Tag</h3>
          <p>{{ bestDay }}</p>
        </div>
        <div v-if="worstDay">
          <h3>Schlechtester Tag</h3>
          <p>{{ worstDay }}</p>
        </div>
      </div>
      <div class="charts-container">
        <div v-if="avgMoodPerDay" class="chart">
          <h3>Durchschnittliche Stimmung pro Tag</h3>
          <line-chart :key="lineChartKey" :chart-data="avgMoodPerDay" />
        </div>
        <div v-if="moodDistPerDay" class="chart">
          <h3>Stimmungsverteilung pro Tag</h3>
          <bar-chart :key="barChartKey" :chart-data="moodDistPerDay" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SideBar from './SideBar.vue';
import LineChart from './LineChart.vue';
import BarChart from './BarChart.vue';
import axios from 'axios';

export default {
  name: 'AnalyticsPage',
  components: {
    SideBar,
    LineChart,
    BarChart,
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      avgMood: null,
      avgMoodPerDay: null,
      bestDay: null,
      worstDay: null,
      moodDistPerDay: null,
      lineChartKey: 0,
      barChartKey: 0,
    };
  },
  created() {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    this.endDate = today.toISOString().split('T')[0];
    this.startDate = sevenDaysAgo.toISOString().split('T')[0];

    this.fetchAnalytics();
  },
  methods: {
    async fetchAnalytics() {
      const params = { startDate: this.startDate, endDate: this.endDate };
      
      try {
        const avgMoodRes = await axios.get('https://api.moodboard.one/analytics/avg-mood', { params, withCredentials: true });
        this.avgMood = avgMoodRes.data.avg_mood;

        const avgMoodPerDayRes = await axios.get('https://api.moodboard.one/analytics/avg-mood-per-day', { params, withCredentials: true });
        this.avgMoodPerDay = avgMoodPerDayRes.data;

        const bestDayRes = await axios.get('https://api.moodboard.one/analytics/best-day', { params, withCredentials: true });
        this.bestDay = bestDayRes.data.best_day;

        const worstDayRes = await axios.get('https://api.moodboard.one/analytics/worst-day', { params, withCredentials: true });
        this.worstDay = worstDayRes.data.worst_day;

        const moodDistPerDayRes = await axios.get('https://api.moodboard.one/analytics/mood-dist-per-day', { params, withCredentials: true });
        this.moodDistPerDay = moodDistPerDayRes.data;
        
        this.updateCharts();

      } catch (error) {
        console.error('Error fetching analytics:', error);
      }
    },
    
    updateCharts() {
      this.lineChartKey += 1;
      this.barChartKey += 1;
    }
  }
};
</script>

<style scoped>
body {
  background-color: #191624; /* Hintergrund */
  color: #ffffff; /* Standard Schriftfarbe */
}

.container {
  display: flex;
}

.content {
  margin: 0 auto; /* Adjust according to your sidebar width */
  padding: 20px;
  flex-grow: 1;
  background-color: #191624; /* Hintergrund */
}

h2 {
  text-align: center;
  color: #a688fa; /* Schriftfarbe für Titel */
  margin-bottom: 20px;
}

label {
  color: #ffffff; /* Schriftfarbe für Label */
}

input[type="date"] {
  background-color: #2c2936; /* Hintergrund Input Field */
  color: #6b6b6d; /* Schriftfarbe Input Field */
  border: 1px solid #6b6b6d;
  border-radius: 5px;
  padding: 5px;
}

input[type="date"]:focus {
  border-color: #a688fa; /* Rahmenfarbe bei Fokus */
  outline: none;
}

button {
  padding: 10px 15px;
  background-color: #a688fa; /* Hintergrundfarbe Button */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #9162fa; /* Hintergrundfarbe Button bei Hover */
}

.analytics-summary {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.analytics-summary div {
  flex: 1;
  text-align: center;
  margin: 0 10px;
  background-color: #2e2b39; /* Hintergrund */
  padding: 20px;
  border-radius: 8px;
}

h3 {
  color: #fff; /* Schriftfarbe für Titel */
}

.charts-container {
  display: flex;
  justify-content: space-between; /* Align charts side by side */
  margin-top: 20px;
}

.chart {
  width: 45%; /* Adjust the width to ensure they fit side by side */
  padding: 10px;
  background-color: #2e2b39; /* Hintergrund */
  border-radius: 8px;
}
</style>
