<template>
  <div class="signup-container">
    <h2>Registrierung</h2>
    <form @submit.prevent="handleSignup">
      <div class="form-group">
        <label for="firstname">Vorname:</label>
        <input type="text" v-model="firstname" required />
      </div>
      <div class="form-group">
        <label for="lastname">Nachname:</label>
        <input type="text" v-model="lastname" required />
      </div>
      <div class="form-group">
        <label for="email">E-Mail:</label>
        <input type="email" v-model="email" required />
      </div>
      <div class="form-group">
        <label for="password">Passwort:</label>
        <input type="password" v-model="password" required />
      </div>
      <div class="form-group">
        <label for="password-confirm">Passwort bestätigen:</label>
        <input type="password" v-model="passwordConfirmation" required />
      </div>
      <div class="form-group">
        <label for="organization">Organisation:</label>
        <input type="text" v-model="organization" required />
      </div>
      <div class="form-group">
        <label for="timezone">Zeitzone:</label>
        <select v-model="defaultTimezone">
          <option v-for="zone in timeZones" :key="zone" :value="zone">{{ zone }}</option>
        </select>
      </div>
      <div class="form-group">
        <label for="notification-time">Benachrichtigungsuhrzeit:</label>
        <input type="time" v-model="defaultNotificationTime" required />
      </div>
      <div class="form-group">
        <label>Benachrichtigungstage:</label>
        <div class="checkbox-group">
          <div v-for="day in days" :key="day.value" class="checkbox-item">
            <input type="checkbox" :id="day.value" v-model="defaultNotificationDays[day.value]" />
            <label :for="day.value">{{ day.label }}</label>
          </div>
        </div>
      </div>
      <button type="submit">Registrieren</button>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </form>
  </div>
</template>

<script>
export default {
  name: 'UserSignup',
  data() {
    return {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      organization: '',
      defaultTimezone: 'Europe/Berlin',
      defaultNotificationTime: '08:00', // Standarduhrzeit
      defaultNotificationDays: {
        sunday: false,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
      },
      errorMessage: '', // Für Fehlermeldungen
      days: [
        { value: 'monday', label: 'Montag' },
        { value: 'tuesday', label: 'Dienstag' },
        { value: 'wednesday', label: 'Mittwoch' },
        { value: 'thursday', label: 'Donnerstag' },
        { value: 'friday', label: 'Freitag' },
        { value: 'saturday', label: 'Samstag' },
        { value: 'sunday', label: 'Sonntag' }
      ],
      timeZones: [
        'Europe/Berlin',
        'America/New_York',
        'Asia/Tokyo',
        'Australia/Sydney',
        'Europe/London',
        'Europe/Paris',
        'Europe/Madrid',
        'America/Los_Angeles',
        'America/Chicago',
        'America/Denver'
      ],
    };
  },
  methods: {
    handleSignup() {
      this.errorMessage = ''; // Reset the error message
      const backendUrl = process.env.VUE_APP_BACKEND_URL;

      fetch(`${backendUrl}/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          firstname: this.firstname,
          lastname: this.lastname,
          organization: this.organization,
          default_timezone: this.defaultTimezone,
          default_time: this.defaultNotificationTime,
          default_notification_monday: this.defaultNotificationDays.monday,
          default_notification_tuesday: this.defaultNotificationDays.tuesday,
          default_notification_wednesday: this.defaultNotificationDays.wednesday,
          default_notification_thursday: this.defaultNotificationDays.thursday,
          default_notification_friday: this.defaultNotificationDays.friday,
          default_notification_saturday: this.defaultNotificationDays.saturday,
          default_notification_sunday: this.defaultNotificationDays.sunday,
        }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.userCreated && data.organizationCreated) {
            // Redirect or show success message
            alert('Erfolgreich registriert!');
            this.$router.push('/'); // Optional: Nach dem Registrieren zur Login-Seite umleiten
          } else {
            this.errorMessage = data.error || 'Registrierung fehlgeschlagen';
          }
        })
        .catch(error => {
          console.error('Fehler:', error);
          this.errorMessage = 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.';
        });
    },
  },
};
</script>

<style scoped>
body {
  background-color: #191624;
  color: #ffffff;
}




.checkbox-item input {
  display: none;
  align-items: center;
}
.checkbox-item label {
  background-color: #a688fa;
  color: #fff;
  border-radius: 4px;
  padding: 10px;
}

.checkbox-item input:not(:checked) + label {
  background-color: #2c2936;
  color: #908d96;
  border-radius: 4px;
  padding: 10px;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.checkbox-item {
    display: flex;
    align-items: center;
    gap: 5px;
}




.signup-container {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #2e2b39;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #a688fa;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #908d96;
}

input,
select {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #6b6b6d;
  border-radius: 4px;
  background-color: #2c2936; /* Hintergrundfarbe des Eingabefelds */
  color: #6b6b6d; /* Schriftfarbe des Eingabefelds */
}

input::placeholder {
  color: #6b6b6d;
}

input:focus {
  border-color: #a688fa;
  outline: none;
}

button {
  width: 100%;
  padding: 10px;
  background-color: #a688fa;
  border: none;
  color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #9162fa;
}

.error {
  color: red; /* Fehlernachricht rot anzeigen */
  text-align: center;
}
</style>
