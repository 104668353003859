<template>
  <div class="admin-container">
    <SideBar />
    <h2>Administration</h2>

    <!-- Default Einstellungen -->
    <section class="default-settings">
      <h3>Default Einstellungen</h3>

      <!-- Benachrichtigungsuhrzeit -->
      <div>
        <label for="notification-time">Benachrichtigungsuhrzeit:</label>
        <input type="time" v-model="defaultNotificationTime">
      </div>

      <!-- Benachrichtigungstage -->
      <div>
        <label>Benachrichtigungstage:</label>
        <div class="checkbox-group">
          <div v-for="day in days" :key="day.value" class="checkbox-item">
            <input type="checkbox" :id="day.value" v-model="defaultNotificationDays[day.value]" />
            <label :for="day.value">{{ day.label }}</label>
          </div>
        </div>
      </div>

      <!-- Zeitzone -->
      <div>
        <label for="timezone">Zeitzone:</label>
        <select v-model="defaultTimezone">
          <option v-for="zone in timeZones" :key="zone" :value="zone">{{ zone }}</option>
        </select>
      </div>

      <!-- Speichern-Button -->
      <button @click="saveSettings">Einstellungen speichern</button>
    </section>

    <!-- Benutzerverwaltung -->
    <section class="user-management">
      <h3>Benutzerverwaltung</h3>
      <div>
        <label for="email">Benutzer einladen:</label>
        <input type="email" v-model="inviteEmail" placeholder="E-Mail eingeben">
        <button @click="inviteUser">Einladen</button>
      </div>

      <div>
        <h4>Bestehende Nutzer ({{ users.length }})</h4>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>E-Mail</th>
              <th>Status</th>
              <th>Rolle</th>
              <th>Aktionen</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.status }}</td>
              <td>
                <select v-model="user.role" @change="updateUserRole(user)">
                  <option value="member">Member</option>
                  <option value="admin">Admin</option>
                  <option value="owner">Owner</option>
                </select>
              </td>
              <td>
                <button @click="deleteUser(user.id)">Löschen</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import SideBar from './SideBar.vue';

export default {
  name: 'AdministrationPage',
  components: {
    SideBar
  },
  setup() {
    const defaultNotificationTime = ref('');
    const defaultTimezone = ref('Europe/Berlin'); // Standard-Zeitzone
    const inviteEmail = ref('');
    const users = ref([]);

    // Benachrichtigungstage
    const defaultNotificationDays = ref({
      sunday: false,
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
    });

    const days = [
      { value: 'sunday', label: 'Sonntag' },
      { value: 'monday', label: 'Montag' },
      { value: 'tuesday', label: 'Dienstag' },
      { value: 'wednesday', label: 'Mittwoch' },
      { value: 'thursday', label: 'Donnerstag' },
      { value: 'friday', label: 'Freitag' },
      { value: 'saturday', label: 'Samstag' }
    ];

    const timeZones = [
      'Europe/Berlin',
      'America/New_York',
      'Asia/Tokyo',
      'Australia/Sydney',
      'Europe/London',
      'Europe/Paris',
      'Europe/Madrid',
      'America/Los_Angeles',
      'America/Chicago',
      'America/Denver'
    ];

    // Benutzer abrufen
    const fetchUsers = () => {
      fetch('https://api.moodboard.one/users', {
        credentials: 'include',
      })
      .then(response => response.json())
      .then(data => {
        users.value = data || [];
      })
      .catch(error => {
        console.error('Fehler beim Abrufen der Benutzer:', error);
        users.value = [];
      });
    };

    // Funktion, um die Standardwerte für Benachrichtigungszeit, Tage und Zeitzone abzurufen
    const fetchDefaultSettings = () => {
      // Holen der Benachrichtigungszeit
      fetch('https://api.moodboard.one/administration/notification-time', {
        credentials: 'include',
      })
      .then(response => response.json())
      .then(data => {
        if (data.time) {
          defaultNotificationTime.value = data.time; // Aktuelle Benachrichtigungszeit setzen
        }
      })
      .catch(error => console.error('Fehler beim Laden der Benachrichtigungszeit:', error));

      // Holen der Benachrichtigungstage
      fetch('https://api.moodboard.one/administration/notification-days', {
        credentials: 'include',
      })
      .then(response => response.json())
      .then(data => {
        defaultNotificationDays.value.sunday = data.sunday;
        defaultNotificationDays.value.monday = data.monday;
        defaultNotificationDays.value.tuesday = data.tuesday;
        defaultNotificationDays.value.wednesday = data.wednesday;
        defaultNotificationDays.value.thursday = data.thursday;
        defaultNotificationDays.value.friday = data.friday;
        defaultNotificationDays.value.saturday = data.saturday;
      })
      .catch(error => console.error('Fehler beim Laden der Benachrichtigungstage:', error));

      // Holen der Zeitzone
      fetch('https://api.moodboard.one/administration/timezone', {
        credentials: 'include',
      })
      .then(response => response.json())
      .then(data => {
        if (data.timezone) {
          defaultTimezone.value = data.timezone; // Aktuelle Zeitzone setzen
        }
      })
      .catch(error => console.error('Fehler beim Laden der Zeitzone:', error));
    };

    // Speichere alle Einstellungen (Benachrichtigungszeit, Tage, Zeitzone)
    // Speichere alle Einstellungen (Benachrichtigungszeit, Tage, Zeitzone)
    // Speichere alle Einstellungen (Benachrichtigungszeit, Tage, Zeitzone)
    const saveSettings = () => {
      // Speichere Benachrichtigungsuhrzeit
      fetch('https://api.moodboard.one/administration/notification-time', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ time: defaultNotificationTime.value }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('Benachrichtigungsuhrzeit aktualisiert');
        }
      })
      .catch(error => console.error('Fehler beim Aktualisieren der Benachrichtigungsuhrzeit:', error));

      // Speichere Benachrichtigungstage
      fetch('https://api.moodboard.one/administration/notification-days', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ 
          notificationDays: {
            sunday: defaultNotificationDays.value.sunday,
            monday: defaultNotificationDays.value.monday,
            tuesday: defaultNotificationDays.value.tuesday,
            wednesday: defaultNotificationDays.value.wednesday,
            thursday: defaultNotificationDays.value.thursday,
            friday: defaultNotificationDays.value.friday,
            saturday: defaultNotificationDays.value.saturday
          }
        }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('Benachrichtigungstage aktualisiert');
        }
      })
      .catch(error => console.error('Fehler beim Aktualisieren der Benachrichtigungstage:', error));

      // Speichere Zeitzone
      fetch('https://api.moodboard.one/administration/timezone', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ timezone: defaultTimezone.value }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          console.log('Zeitzone aktualisiert');
        }
      })
      .catch(error => console.error('Fehler beim Aktualisieren der Zeitzone:', error));
    };


    // Benutzer einladen
    const inviteUser = () => {
      fetch('https://api.moodboard.one/administration/invite', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email: inviteEmail.value }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Benutzer erfolgreich eingeladen!');
          inviteEmail.value = '';
        } else {
          alert(data.error || 'Fehler beim Einladen des Benutzers');
        }
      })
      .catch(error => console.error('Fehler beim Einladen des Benutzers:', error));
    };

    // Benutzerrolle aktualisieren
    const updateUserRole = (user) => {
      fetch(`https://api.moodboard.one/users/${user.id}/role`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ role: user.role }),
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Benutzerrolle aktualisiert!');
        }
      })
      .catch(error => console.error('Fehler beim Aktualisieren der Benutzerrolle:', error));
    };

    // Benutzer löschen
    const deleteUser = (userId) => {
      fetch(`https://api.moodboard.one/administration/users/${userId}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include'
      })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          alert('Benutzer erfolgreich gelöscht!');
          fetchUsers(); // Benutzerliste nach dem Löschen aktualisieren
        }
      })
      .catch(error => console.error('Fehler beim Löschen des Benutzers:', error));
    };

    onMounted(() => {
      fetchUsers();
      fetchDefaultSettings();
    });

    return {
      defaultNotificationTime,
      defaultNotificationDays,
      defaultTimezone,
      inviteEmail,
      users,
      saveSettings, // Für den Speichern-Button
      inviteUser,
      updateUserRole,
      deleteUser,
      days,
      timeZones
    };
  }
};
</script>




<style scoped>

.admin-container {
  max-width: 800px;


  padding: 20px;

  background-color: #2e2b39;

  color: #ffffff;

  border-radius: 10px;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;

}



h2 {

  text-align: center;

  color: #a688fa;

  margin-bottom: 20px;

}



h3 {

  color: #a688fa;

  margin-top: 20px;

}



label {

  display: block;

  margin-top: 10px;

  color: #ffffff;

}


input[type="time"],

select {

  width: fit-content;

  padding: 10px;

  margin-top: 5px;

  border: 1px solid #6b6b6d;

  border-radius: 5px;

  background-color: #2c2936;

  color: #ffffff;

}

input[type="text"],
input[type="email"],
select {

  width: 100%;

  padding: 10px;

  margin-top: 5px;

  border: 1px solid #6b6b6d;

  border-radius: 5px;

  background-color: #2c2936;

  color: #ffffff;

}



button {

  padding: 10px 15px;

  margin-top: 10px;

  background-color: #a688fa;

  color: white;

  border: none;

  border-radius: 5px;

  cursor: pointer;

}



button:hover {

  background-color: #9162fa;

}



table {

  width: 100%;

  border-collapse: collapse;

  margin-top: 20px;

}



th,

td {

  padding: 10px;

  border: 1px solid #6b6b6d;

}



th {

  background-color: #2c2936;

  color: #ffffff;

}



td {

  background-color: #2e2b39;

  color: #ffffff;

}



.checkbox-group {

  display: flex;

  flex-wrap: wrap;

}



.checkbox-item {

  width: 50%;

}

</style>
