import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';

// Set up the base URL for axios
axios.defaults.baseURL = 'https://api.moodboard.one';
axios.defaults.withCredentials = true;


createApp(App).use(router).mount('#app');
