<template>
  <div class="chart-container">
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script>
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

export default {
  props: ['chartData'],
  mounted() {
    const labels = Object.keys(this.chartData);
    const datasets = [];
    const moodLabels = ['1', '2', '3', '4', '5'];
    const moodDescriptions = {
      '1': 'Sehr traurig',
      '2': 'Traurig',
      '3': 'Neutral',
      '4': 'Glücklich',
      '5': 'Sehr glücklich'
    };

    moodLabels.forEach(mood => {
      const data = labels.map(date => this.chartData[date][mood]);
      datasets.push({
        label: moodDescriptions[mood],
        data,
        backgroundColor: this.getColor(mood),
      });
    });

    new Chart(this.$refs.canvas, {
      type: 'bar',
      data: {
        labels,
        datasets
      },
      options: {
        scales: {
          x: {
            stacked: true
          },
          y: {
            stacked: true,
            beginAtZero: true
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              title: function(context) {
                return `Datum: ${context[0].label}`;
              },
              label: function(context) {
                const label = context.dataset.label || '';
                const value = context.raw;
                return `${label}: ${value}`;
              },
              footer: function(context) {
                const moods = context[0].chart.data.datasets.map(dataset => {
                  const moodValue = dataset.data[context[0].dataIndex];
                  return `${dataset.label}: ${moodValue}`;
                });
                return ['---', ...moods];
              }
            }
          }
        }
      }
    });
  },
  methods: {
    getColor(mood) {
      const colors = {
        '1': 'rgba(250, 160, 160, 1)',
        '2': 'rgba(250, 200, 152, 1)',
        '3': 'rgba(239, 234, 141, 1)',
        '4': 'rgba(167, 199, 231, 1)',
        '5': 'rgba(119, 221, 119, 1)'
      };
      return colors[mood];
    }
  }
};
</script>

<style scoped>
.chart-container {
  width: 100%;
}
</style>
