<template>
  <div class="container">
    <SideBar />
    <div class="content">
      <h2>Stimmungen</h2>

      <div v-if="Object.keys(postsByDate).length === 0 && !loading" class="no-mood-message">
        <p>Keine Stimmungen vorhanden.</p>
      </div>

      <div v-else>
      <div v-for="(posts, date) in postsByDate" :key="date" class="day-container">
        <h3>{{ formatDate(date) }}</h3>
        <div v-if="statsByDate[date]" class="stats">
          <div v-for="(count, mood) in statsByDate[date].mood_count" :key="mood" class="stat-item">
            <img :src="getMoodIcon(parseInt(mood))" alt="Mood Icon" class="mood-icon" />
            <span>{{ count }} ({{ statsByDate[date].mood_percentage[mood] }}%)</span>
          </div>
        </div>
        <div class="masonry" :ref="'masonry-' + date">
          <div class="masonry-item" v-for="post in posts" :key="post.id">
            <div class="mood-icon">
              <img :src="getMoodIcon(post.mood)" alt="Mood Icon" />
            </div>
            <p class="large-quotes">{{ post.message }}</p>
            <div class="post-actions">
              <button :class="{ voted: postStates[post.id]?.liked }" @click="vote(post.id, true)">
                👍 {{ post.likes }}
              </button>
              <button :class="{ voted: postStates[post.id]?.disliked }" @click="vote(post.id, false)">
                👎 {{ post.dislikes }}
              </button>
              <br><br>
              <button @click="openDeletePostModal(post)">Löschen</button>
              <button @click="openReportPostModal(post)">Melden</button>
            </div>
            <div v-if="post.comments.length" class="comments">
              <div class="comment" v-for="comment in post.comments" :key="comment.createdAt">
                <div v-if="comment.isAuthor" class="vertical-ribbon">Autor</div>
                <h4 style="font-size: small">{{ comment.name }}</h4>
                <p>{{ comment.message }}</p>
                <button v-if="comment.isCreator" @click="openDeleteCommentModal(comment)">Löschen</button>
                <button @click="openReportModal(comment)">Melden</button>
              </div>
            </div>
            <div v-else class="comments">
              <div style="align-items: center; display: flex; background-color: aliceblue; justify-content: center; height: 100%;">Keine Kommentare</div>
            </div>
            <!-- New Comment Section -->
            <div class="new-comment">
              <div class="comment-input-row">
                <textarea v-model="postStates[post.id].newComment" placeholder="Was denkst Du darüber?" :class="{ 'highlight': postStates[post.id].highlightEffect }"></textarea>
                <div class="comment-buttons">
                  <button @click="submitComment(post.id)">Senden</button>
                  <button @click="anonymizeComment(post.id)">✨ KI</button>
                </div>
              </div>
              <div class="comment-toggle-row">
                <img :src="incognito" class="switch-icon" alt="Incognito Icon" />
                <label class="switch">
                  <input type="checkbox" v-model="postStates[post.id].anonymous">
                  <span class="slider round"></span>
                </label>
                <div v-if="!postStates[post.id].anonymous" class="non-anonymous-warning">
                  Du kommentierst mit Klarnamen.
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
      </div>
      </div>
      <div ref="bottom" class="bottom"></div>
      <div v-if="loading" class="loading">
        <div class="spinner"></div>
      </div>
    </div>

    <!-- Report Modal -->
    <div v-if="showReportModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeReportModal">&times;</span>
        <h2>Kommentar melden</h2>
        <textarea v-model="reportReason" placeholder="Grund für die Meldung"></textarea>
        <button @click="submitReport">Absenden</button>
      </div>
    </div>

    <!-- Delete Comment Modal -->
    <div v-if="showDeleteCommentModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeDeleteCommentModal">&times;</span>
        <h2>Kommentar löschen</h2>
        <p>Bist du sicher, dass du diesen Kommentar löschen möchtest?</p>
        <button @click="confirmDeleteComment">Ja, löschen</button>
        <button @click="closeDeleteCommentModal">Abbrechen</button>
      </div>
    </div>

    <!-- Report Post Modal -->
    <div v-if="showReportPostModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeReportPostModal">&times;</span>
        <h2>Post melden</h2>
        <textarea v-model="reportReason" placeholder="Grund für die Meldung"></textarea>
        <button @click="submitReportPost">Absenden</button>
      </div>
    </div>

    <!-- Delete Post Modal -->
    <div v-if="showDeletePostModal" class="modal">
      <div class="modal-content">
        <span class="close" @click="closeDeletePostModal">&times;</span>
        <h2>Post löschen</h2>
        <p>Bist du sicher, dass du diesen Post löschen möchtest?</p>
        <button @click="confirmDeletePost">Ja, löschen</button>
        <button @click="closeDeletePostModal">Abbrechen</button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive, onMounted, nextTick, onBeforeUnmount, watch } from 'vue';
import Masonry from 'masonry-layout';
import mood1 from '@/assets/1.svg';
import mood2 from '@/assets/2.svg';
import mood3 from '@/assets/3.svg';
import mood4 from '@/assets/4.svg';
import mood5 from '@/assets/5.svg';
import incognito from '@/assets/incognito.svg';
import author from '@/assets/author.svg';
import SideBar from './SideBar.vue';

export default {
  name: 'PostsPage',
  components: {
    SideBar
  },
  setup() {
    const postsByDate = reactive({});
    const statsByDate = reactive({});
    const loading = ref(false);
    const currentDate = ref(new Date().toLocaleDateString('sv-SE', { timeZone: 'Europe/Berlin' }));
    const nextDate = ref(null);
    const observer = ref(null);
    const bottom = ref(null);
    const showReportModal = ref(false);
    const showDeleteCommentModal = ref(false);
    const showReportPostModal = ref(false);
    const showDeletePostModal = ref(false);
    const reportReason = ref('');
    const selectedComment = ref(null);
    const selectedPost = ref(null);

    const postStates = reactive({});

    const loadInitialPosts = () => {
      fetchPostsByDate(currentDate.value).then(() => {
        nextTick(() => {
          if (isContentShort() && nextDate.value) {
            loadMorePosts();
          } else {
            initIntersectionObserver();
          }
        });
      });
    };

    const formatDate = (dateString) => {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      return new Date(dateString).toLocaleDateString('de-DE', options);
    };

    const loadMorePosts = () => {
      if (nextDate.value) {
        fetchPostsByDate(nextDate.value).then(() => {
          nextTick(() => {
            if (isContentShort() && nextDate.value) {
              loadMorePosts();
            } else {
              initIntersectionObserver();
            }
          });
        });
      }
    };

    const isContentShort = () => {
      return document.documentElement.scrollHeight <= document.documentElement.clientHeight;
    };

    const fetchPostsByDate = (date) => {
      if (loading.value) return Promise.resolve();
      loading.value = true;
      console.log(`Fetching posts for date: ${date}`);

      const backendUrl = 'https://api.moodboard.one';
      return fetch(`${backendUrl}/posts/dates/${date}`, {
        credentials: 'include',
      })
        .then(response => response.json())
        .then(data => {
          nextDate.value = data.next_date;
          if (!data.posts || data.posts.length === 0) {
            console.log('No more posts available.');
          } else {
            postsByDate[date] = data.posts;
            data.posts.forEach(post => {
              postStates[post.id] = {
                newComment: '',
                anonymous: true,
                liked: post.hasVoted && post.like === true,
                disliked: post.hasVoted && post.like === false,
              };
            });
          }
          console.log(`Next date set to: ${nextDate.value}`);
          return fetchStatsByDate(date);
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const fetchStatsByDate = (date) => {
      const backendUrl = 'https://api.moodboard.one';
      return fetch(`${backendUrl}/posts/stats/${date}`, {
        credentials: 'include',
      })
        .then(response => response.json())
        .then(data => {
          statsByDate[date] = data;
        })
        .catch(error => {
          console.error('Error fetching stats:', error);
        });
    };

    const initMasonry = (date) => {
      nextTick(() => {
        const container = bottom.value.parentElement.querySelector(`[ref="masonry-${date}"]`);
        if (container) {
          new Masonry(container, {
            itemSelector: '.masonry-item',
            columnWidth: '.masonry-item',
            percentPosition: true,
            gutter: 10,
          });
        }
      });
    };

    const initIntersectionObserver = () => {
      const options = {
        root: null,
        rootMargin: '200px',
        threshold: 0.1,
      };

      observer.value = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          console.log('IntersectionObserver entry:', entry);
          if (entry.isIntersecting && nextDate.value) {
            console.log('Bottom of the page reached. Loading more posts...');
            fetchPostsByDate(nextDate.value);
          } else {
            console.log('Bottom of the page not reached or no more posts to load.');
            console.log(nextDate.value);
            console.log(entry.isIntersecting);
          }
        });
      }, options);

      observer.value.observe(bottom.value);
    };

    const reportComment = (commentId, reason) => {
      fetch('https://api.moodboard.one/commentreports', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ commentId: commentId, message: reason }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            alert('Comment reported successfully');
            closeReportModal();
          } else {
            alert('Failed to report comment');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

    const reportPost = (postId, reason) => {
      fetch('https://api.moodboard.one/postreports', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ postId: postId, message: reason }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            alert('Post reported successfully');
            closeReportPostModal();
          } else {
            alert('Failed to report post');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

    const submitComment = (postId) => {
      const postState = postStates[postId];
      const commentData = {
        message: postState.newComment,
        postId: postId,
        isAuthor: true,
        reported: false,
        anonymous: postState.anonymous,
      };
      fetch('https://api.moodboard.one/comments', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(commentData),
        credentials: 'include'
      })
        .then(response => response.json())
        .then(data => {
          if (data.id) {
            const post = Object.values(postsByDate).flat().find(post => post.id === postId);
            post.comments.push({
              isAuthor: true,
              message: postState.newComment,
              name: data.name,
              isCreator: true,
            });
            // Clear the comment input
            postState.newComment = '';
            postState.anonymous = true;
          } else {
            alert('Failed to submit comment');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });

      // Logic to submit the comment to the server or add to the post.comments
      console.log('Submitting comment:', commentData);
    };

    const deleteComment = (commentId) => {
      fetch(`https://api.moodboard.one/comments/${commentId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            // Finde den Post, zu dem der Kommentar gehört
            const post = Object.values(postsByDate).flat().find(post => 
              post.comments.some(comment => comment.id === commentId)
            );

            // Aktualisiere die Kommentare des gefundenen Posts
            if (post) {
              post.comments = post.comments.filter(comment => comment.id !== commentId);
            }
            closeDeleteCommentModal();
          } else {
            alert('Failed to delete comment');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

    const deletePost = (postId) => {
      fetch(`https://api.moodboard.one/posts/${postId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include'
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            const dateKey = Object.keys(postsByDate).find(date => 
              postsByDate[date].some(post => post.id === postId)
            );

            if (dateKey) {
              postsByDate[dateKey] = postsByDate[dateKey].filter(post => post.id !== postId);
            }
            closeDeletePostModal();
          } else {
            alert('Failed to delete post');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

    const openReportModal = (comment) => {
      selectedComment.value = comment;
      showReportModal.value = true;
    };

    const closeReportModal = () => {
      selectedComment.value = null;
      showReportModal.value = false;
      reportReason.value = '';
    };

    const submitReport = () => {
      if (selectedComment.value && reportReason.value) {
        reportComment(selectedComment.value.id, reportReason.value);
      }
    };

    const openDeleteCommentModal = (comment) => {
      selectedComment.value = comment;
      showDeleteCommentModal.value = true;
    };

    const closeDeleteCommentModal = () => {
      selectedComment.value = null;
      showDeleteCommentModal.value = false;
    };

    const confirmDeleteComment = () => {
      if (selectedComment.value) {
        deleteComment(selectedComment.value.id);
      }
    };

    const openReportPostModal = (post) => {
      selectedPost.value = post;
      showReportPostModal.value = true;
    };

    const closeReportPostModal = () => {
      selectedPost.value = null;
      showReportPostModal.value = false;
      reportReason.value = '';
    };

    const submitReportPost = () => {
      if (selectedPost.value && reportReason.value) {
        reportPost(selectedPost.value.id, reportReason.value);
      }
    };

    const openDeletePostModal = (post) => {
      selectedPost.value = post;
      showDeletePostModal.value = true;
    };

    const closeDeletePostModal = () => {
      selectedPost.value = null;
      showDeletePostModal.value = false;
    };

    const confirmDeletePost = () => {
      if (selectedPost.value) {
        deletePost(selectedPost.value.id);
      }
    };

    const anonymizeComment = (postId) => {
      const postState = postStates[postId];
      fetch('https://api.moodboard.one/comments/anonymize', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({ message: postState.newComment }),
      })
        .then(response => response.json())
        .then(data => {
          if (data.message) {
            
            // Effekt beim Textfeld hinzufügen
            postState.highlightEffect = true; // Setze den Effekt auf true
            postState.newComment = data.message;

            // Effekt nach 800ms zurücksetzen
            setTimeout(() => {
              postState.highlightEffect = false;
            }, 800);
            
          } else {
            alert('Failed to anonymize comment');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

    const vote = (postId, like) => {
      const postState = postStates[postId];
      const currentLikeState = postState.liked;
      const currentDislikeState = postState.disliked;

      const data = {
        postId,
        like: currentLikeState ? null : (like ? true : currentDislikeState ? null : false)
      };

      fetch('https://api.moodboard.one/votes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
      })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            const post = Object.values(postsByDate).flat().find(post => post.id === postId);
            if (like) {
              postState.liked = !currentLikeState;
              postState.disliked = false;
              if (currentLikeState) {
                post.likes--;
              } else {
                post.likes++;
                if (currentDislikeState) {
                  post.dislikes--;
                }
              }
            } else {
              postState.disliked = !currentDislikeState;
              postState.liked = false;
              if (currentDislikeState) {
                post.dislikes--;
              } else {
                post.dislikes++;
                if (currentLikeState) {
                  post.likes--;
                }
              }
            }
          } else {
            alert('Failed to submit vote');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    };

    onMounted(() => {
      loadInitialPosts();
    });

    onBeforeUnmount(() => {
      if (observer.value) {
        observer.value.disconnect();
      }
    });

    watch(postsByDate, () => {
      nextTick(() => {
        Object.keys(postsByDate).forEach(date => {
          initMasonry(date);
        });
      });
    });

    const getMoodIcon = (mood) => {
      switch (mood) {
        case 1:
          return mood1;
        case 2:
          return mood2;
        case 3:
          return mood3;
        case 4:
          return mood4;
        case 5:
          return mood5;
        default:
          return '';
      }
    };

    return {
      postsByDate,
      statsByDate,
      loading,
      bottom,
      reportComment,
      reportPost,
      getMoodIcon,
      incognito,
      author,
      postStates,
      submitComment,
      deleteComment,
      deletePost,
      vote,
      showReportModal,
      showDeleteCommentModal,
      showReportPostModal,
      showDeletePostModal,
      reportReason,
      openReportModal,
      closeReportModal,
      submitReport,
      openDeleteCommentModal,
      closeDeleteCommentModal,
      confirmDeleteComment,
      openReportPostModal,
      closeReportPostModal,
      submitReportPost,
      openDeletePostModal,
      closeDeletePostModal,
      confirmDeletePost,
      anonymizeComment,
      formatDate
    };
  },
};
</script>
<style scoped>
body {
  background-color: #191624; /* Hintergrund */
  color: #ffffff; /* Standard Schriftfarbe */
}

.large-quotes {
  position: relative;
  display: inline-block;
}

.large-quotes::before,
.large-quotes::after {
  content: '„';
  font-size: 1.5em; /* Größe der Anführungszeichen anpassen */
  color: inherit;
}

.large-quotes::after {
  content: '“';
  right: 0;
}

.large-quotes::before {
  left: 0;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #191624; /* Hintergrund */
}

.content {
  background-color: #191624; /* Hintergrund */
}

h2 {
  text-align: center;
  color: #a688fa; /* Schriftfarbe für Titel */
  margin-bottom: 20px;
}

.day-container {
  margin-bottom: 40px;
}

h3 {
  color: #a688fa; /* Schriftfarbe für Datum */
}

.stats {
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  color: #ffffff; /* Schriftfarbe für Stats */
}

.stat-item {
  display: flex;
  align-items: center;
  margin-right: 15px;
}

.stat-item img {
  margin-right: 5px;
  width: 25px;
}

.stat-item span {
  color: #ffffff; /* Schriftfarbe für Stats */
}

.masonry {
  display: flex;
  flex-wrap: wrap;
  margin: -10px; /* Adjust for gutter */
  position: relative;
}

.masonry-item {
  background-color: #2e2b39; /* Hintergrund Posts */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  box-sizing: border-box;
  transition: transform 0.2s ease-in-out;
  width: calc(33.333% - 20px); /* Ensure three columns with some gutter */
}

.masonry-item:hover {
  transform: translateY(-5px);
}

.masonry-item h3 {
  color: #a688fa; /* Schriftfarbe für Post-Titel */
  margin-top: 0;
}

.masonry-item p {
  margin-bottom: 10px;
  color: #908d96; /* Schriftfarbe für Post-Text */
}

.post-actions button {
  background-color: transparent; /* Hintergrundfarbe der Buttons */
  border: none;
  color: #a688fa; /* Schriftfarbe der Buttons */
  cursor: pointer;
  margin-right: 10px;
}

.post-actions button.voted {
  color: #9162fa; /* Schriftfarbe der Buttons bei Aktivierung */
}

.post-actions button:hover {
  color: #9162fa; /* Schriftfarbe der Buttons bei Hover */
}

.comments {
  margin-top: 10px;
  max-height: 150px; /* Set the maximum height for the comments container */
  min-height: 150px;
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: #45424e; /* Hintergrund Comments */
  padding: 10px; /* Optional: add some padding for better readability */
  color: #908d96; /* Schriftfarbe Comments (normaler Text) */
}

.comment {
  position: relative;
  background-color: #2e2b39; /* Hintergrund Einzelne Comment */
  padding: 10px;
  border-radius: 5px;
  margin-top: 10px;
  color: #908d96; /* Schriftfarbe Comments (normaler Text) */
}

.comment h4 {
  color: white; /* Schriftfarbe Name/Pseudonym im Comment */
}

.comment button {
  background-color: transparent; /* Hintergrundfarbe der Buttons */
  border: none;
  color: #ff6347; /* Schriftfarbe der Buttons */
  cursor: pointer;
  margin-left: 10px;
}

.comment button:hover {
  color: #ff4500; /* Schriftfarbe der Buttons bei Hover */
}

.new-comment {
  margin-top: 10px;
}

.comment-input-row {
  display: flex;
  align-items: center;
}

.comment-input-row textarea {
  width: 100%;
  height: 60px;
  margin-right: 10px;
  padding: 10px;
  background-color: #2c2936; /* Hintergrund Input Field */
  color: #6b6b6d; /* Schriftfarbe Input Field */
  border: 1px solid #6b6b6d;
  border-radius: 5px;
  box-sizing: border-box;
}

.comment-input-row textarea:focus {
  border-color: #a688fa; /* Rahmenfarbe bei Fokus */
  outline: none;
}

.comment-input-row .comment-buttons {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.comment-input-row button {
  padding: 10px 15px;
  background-color: #a688fa; /* Hintergrundfarbe Button Senden für Comments */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.comment-input-row button:hover {
  background-color: #9162fa; /* Hintergrundfarbe Button bei Hover */
}

.comment-toggle-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 20px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-left: 10px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #007bff;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.switch-label {
  margin-left: 10px;
  vertical-align: middle;
}

.non-anonymous-warning {
  margin-left: 10px;
  color: red;
}

.bottom {
  width: 100%;
  height: 1px;
}

.loading {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
  color: #007bff;
}

.spinner {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 900px) {
  .masonry-item {
    width: calc(50% - 20px); /* Two columns */
  }
}

@media (max-width: 600px) {
  .masonry-item {
    width: calc(100% - 20px); /* One column */
  }
}

.switch-icon {
  margin-left: 10px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  filter: invert(11%) sepia(96%) saturate(6444%) hue-rotate(277deg) brightness(72%) contrast(100%);
}

.author-icon {
  margin-left: 10px;
  vertical-align: middle;
  width: 20px;
  height: 20px;
  filter: invert(21%) sepia(93%) saturate(798%) hue-rotate(179deg) brightness(91%) contrast(85%);
}

.vertical-ribbon {
  position: absolute;
  top: 73px;
  right: -40px; /* Passe diesen Wert nach Bedarf an, um die richtige Position zu erhalten */
  background-color: #ff6347; /* Farbe nach Wunsch ändern */
  color: white;
  padding: 5px 20px;
  transform: rotate(-90deg);
  transform-origin: left top;
  font-size: 14px;
  font-weight: bold;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%, 20% 50%, 0 0%);
}

/* Modal styles */
.modal {
  display: block; /* Hidden by default */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); /* Black with opacity */
}

.modal-content {
  background-color: #2e2b39;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  border-radius: 10px;
}

.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #2c2936; /* Hintergrund Input Field */
  color: #6b6b6d; /* Schriftfarbe Input Field */
  border: 1px solid #6b6b6d;
  border-radius: 5px;
  box-sizing: border-box;
}

.modal button {
  padding: 10px 15px;
  background-color: #a688fa; /* Hintergrundfarbe Button Senden für Comments */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #9162fa; /* Hintergrundfarbe Button bei Hover */
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, #333, #888, #333);
  margin: 60px 0; /* Anpassung des Abstands, falls gewünscht */
}

.no-mood-message {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2e2b39; /* Hintergrund */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Schattierung für Tiefeneffekt */
  margin: 20px 0;
}

.no-mood-message p {
  font-size: 1.2em; /* Größere Schriftgröße */
  color: #a688fa; /* Helle und auffällige Schriftfarbe */
  text-align: center;
  margin: 0;
}

/* Visueller Effekt (z.B. Aufleuchten oder Pulsieren) */
.highlight {
  animation: pulse 0.8s ease-in-out 1;
}

@keyframes pulse {
  0% {
    background-color: #2c2936;
  }
  50% {
    background-color: #6a5acd; /* Leichte Farbänderung während des Pulsierens */
  }
  100% {
    background-color: #2c2936; /* Zurück zum ursprünglichen Hintergrund */
  }
}
</style>
