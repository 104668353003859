import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '@/components/UserLogin.vue';
import HomePage from '@/components/HomePage.vue';
import PostPage from '@/components/PostPage.vue';
import AnalyticsPage from '@/components/AnalyticsPage.vue';
import SettingsPage from '@/components/SettingsPage.vue';
import AdministrationPage from '@/components/AdministrationPage.vue';
import SignUpPage from '@/components/SignUpPage.vue';
import axios from 'axios';


const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin,
  },
  {
    path: '/home',
    name: 'HomePage',
    component: HomePage,
    meta: { requiresAuth: true }
  },
  {
    path: '/signup',
    name: 'SignUpPage',
    component: SignUpPage
  },
  {
    path: '/post',
    name: 'PostPage',
    component: PostPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/post/token/:id',
    name: 'PostPageWithToken',
    component: PostPage
    // Keine Authentifizierung erforderlich
  },
  {
    path: '/analytics',
    name: 'AnalyticsPage',
    component: AnalyticsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'SettingsPage',
    component: SettingsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/administration',
    name: 'AdministrationPage',
    component: AdministrationPage,
    meta: { requiresAuth: true, requiresAdmin: true } 
  }
  // Weitere Routen können hier hinzugefügt werden
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    try {
      const response = await axios.get('https://api.moodboard.one/status', { withCredentials: true });
      if (response.data.success) {
        next();
      } else {
        next('/');
      }
    } catch (error) {
      console.error('Error checking login status:', error);
      next('/');
    }
  } else {
    next();
  }
});

export default router;
